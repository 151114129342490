import dayjs from "dayjs";
import "dayjs/locale/sv";
import { useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useCustomerTheme } from "../contexts/CustomerThemeContext";
import { CLOSE_ICON } from "../utils/theme";
import { AnswerQuestion } from "./AnswerQuestion";
import { FeelingResults } from "./FeelingResults";
import { ContentWrapper } from "./layout/ContentWrapper";
import { MoreQuestions } from "./MoreQuestions";
import { ProfileQuestions } from "./ProfileQuestions";
import { Results } from "./Results";
import { IconButton } from "./ui/IconButton";
import { ImageOverlay } from "./ui/ImageOverlay";
import { Text } from "./ui/Text";

dayjs.locale("sv");

export const MainContentBoxExpanded = ({
  question,
  externalUserId,
  userAnswerOptionId,
  panelId,
  refreshQuestion,
  handleCloseClick,
  handleNewActiveQuestion,
  toggleWindowSize,
  webpageId,
}) => {
  const [selectedAnswerOption, setSelectedAnswerOption] = useState(null);
  const [filterOnAnswerOption, setFilterOnAnswerOption] = useState(null);
  const theme = useCustomerTheme();

  useEffect(() => {
    const mostAnsweredAnswerOption = question.answer_options.sort(
      (a, b) => (b.answers[0]?.count ?? 0) - (a.answers[0]?.count ?? 0)
    )[0];
    setFilterOnAnswerOption(mostAnsweredAnswerOption);
  }, [question]);

  useEffect(() => {
    setSelectedAnswerOption(userAnswerOptionId);
  }, [userAnswerOptionId]);

  const isPastVoteEndDate =
    question.vote_end_date && new Date(question.vote_end_date) < new Date();

  return (
    <ContentWrapper style={{ position: "relative", padding: "8px" }}>
      <div style={{ marginBottom: "16px", paddingTop: "16px" }}>
        {question.image_url ? (
          <div>
            <ImageOverlay
              imageUrl={question.image_url}
              title={question.title}
              description={question.description}
              disableBorderBottom={true}
              style={{ margin: "-38px -20px 12px -22px" }}
            />
            <div
              style={{
                position: "absolute",
                top: "8px",
                right: "8px",
                zIndex: 5,
              }}
            >
              <IconButton
                icon={<MdOutlineClose />}
                onClick={handleCloseClick}
                color={"white"}
                backgroundColor={CLOSE_ICON}
                size={24}
              />
            </div>
          </div>
        ) : (
          <div style={{ paddingTop: "10px" }}>
            <Text variant="title" style={{ color: theme.accent_color }}>
              {question.title}
            </Text>
            <br />
            <Text variant="subtitle">{question.description}</Text>
            <div
              style={{
                position: "absolute",
                top: "4px",
                right: "4px",
                zIndex: 5,
              }}
            >
              <IconButton
                icon={<MdOutlineClose />}
                onClick={handleCloseClick}
                color={CLOSE_ICON}
                size={24}
              />
            </div>
          </div>
        )}
        {isPastVoteEndDate && (
          <Text variant="small" color="gray" style={{ fontStyle: "italic" }}>
            Tiden för att svara på denna fråga löpte ut{" "}
            {dayjs(new Date(question.vote_end_date)).format(
              "DD MMMM YYYY HH:mm"
            )}
          </Text>
        )}
      </div>
      {selectedAnswerOption || isPastVoteEndDate ? (
        <div>
          <Results
            question={question}
            answerOptionId={selectedAnswerOption}
            filterOnAnswerOptionId={
              question.enable_feelings
                ? filterOnAnswerOption
                  ? filterOnAnswerOption.id
                  : null
                : null
            }
            onAnswerOptionClicked={
              question.enable_feelings
                ? (aoId) => {
                    if (
                      filterOnAnswerOption &&
                      filterOnAnswerOption.id === aoId
                    ) {
                      setFilterOnAnswerOption(null);
                    } else {
                      const answerOption = question.answer_options.find(
                        (ao) => ao.id === aoId
                      );
                      setFilterOnAnswerOption(answerOption);
                    }
                  }
                : null
            }
          />
          {question.enable_feelings && (
            <FeelingResults
              question={question}
              filterOnAnswerOption={filterOnAnswerOption}
            />
          )}
          {externalUserId && (
            <ProfileQuestions
              originalQuestionId={question.id}
              externalUserId={externalUserId}
              panelId={panelId ? panelId : undefined}
              webpageId={webpageId}
              userAnswerOptionId={userAnswerOptionId}
            />
          )}
          <MoreQuestions
            handleNewActiveQuestion={handleNewActiveQuestion}
            question={question}
            externalUserId={externalUserId}
            panelId={panelId ? panelId : undefined}
          />
        </div>
      ) : (
        <AnswerQuestion
          skipFeeling={!question.enable_feelings}
          externalUserId={externalUserId ? externalUserId : undefined}
          onClick={(ao) => {
            refreshQuestion();
            setSelectedAnswerOption(ao);
          }}
          question={question}
          webpageId={webpageId}
          panelId={panelId}
        />
      )}
    </ContentWrapper>
  );
};
