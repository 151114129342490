import { useCustomerTheme } from "../../contexts/CustomerThemeContext";
import { Text } from "./Text";

export const ImageOverlay = ({
  imageUrl,
  title,
  description,
  paddingTop = "200px",
  children,
  disableBorderBottom = false,
  style = {},
  titleStyle = {},
  descriptionStyle = {},
  coverOnly = false,
}) => {
  const theme = useCustomerTheme();

  return (
    <div
      style={{
        position: "relative",
        marginBottom: "8px",
        paddingTop: paddingTop,
        borderRadius: theme.border_radius,
        borderBottomRightRadius: disableBorderBottom
          ? "0px"
          : theme.border_radius,
        borderBottomLeftRadius: disableBorderBottom
          ? "0px"
          : theme.border_radius,
        overflow: "hidden",
        ...style,
      }}
    >
      <img
        src={imageUrl}
        alt={title}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: `${theme.border_radius}px` || "8px",
          borderBottomRightRadius: disableBorderBottom
            ? "0px"
            : theme.border_radius,
          borderBottomLeftRadius: disableBorderBottom
            ? "0px"
            : theme.border_radius,
        }}
      />
      <div
        style={{
          position: coverOnly ? "relative" : "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          background: "linear-gradient(transparent, rgba(0, 0, 0, 0.7))",
          padding: "20px",
          borderRadius: theme.border_radius,
          borderBottomRightRadius: disableBorderBottom
            ? "0px"
            : theme.border_radius,
          borderBottomLeftRadius: disableBorderBottom
            ? "0px"
            : theme.border_radius,
        }}
      >
        {title && (
          <Text
            variant="title"
            style={{
              color: "#ffffff",
              textShadow: "0px 0px 12px rgba(0, 0, 0, 0.9)",
              marginBottom: description ? "8px" : 0,
              ...titleStyle,
            }}
          >
            {title}
          </Text>
        )}
        {description && (
          <>
            <br />
            <Text
              variant="body"
              style={{
                color: "#ffffff",
                textShadow: "0px 0px 12px rgba(0, 0, 0, 0.9)",
                margin: 0,
                ...descriptionStyle,
              }}
            >
              {description}
            </Text>
          </>
        )}
        {children}
      </div>
    </div>
  );
};
