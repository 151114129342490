import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useCustomerTheme } from "../contexts/CustomerThemeContext";
import { getLocalStorageItem } from "../utils/localstorage";
import { AnswerQuestion } from "./AnswerQuestion";
import { ContentWrapper } from "./layout/ContentWrapper";
import { Results } from "./Results";
import { ImageOverlay } from "./ui/ImageOverlay";
import { Text } from "./ui/Text";

export const MainContentBox = ({
  question,
  externalUserId,
  userAnswerOptionId,
  refreshQuestion,
  toggleWindowSize,
  webpageId,
  panelId,
}) => {
  const theme = useCustomerTheme();
  const [selectedAnswerOption, setSelectedAnswerOption] =
    useState(userAnswerOptionId);

  const updateSelectedAnswerOptionFromLocalStorage = () => {
    const answerOption = getLocalStorageItem(question.id);
    setSelectedAnswerOption(answerOption ?? null);
  };

  const updateSelectedAnswerOption = () => {
    setSelectedAnswerOption(userAnswerOptionId);
  };

  useEffect(() => {
    if (externalUserId && question) {
      updateSelectedAnswerOption();
    } else {
      updateSelectedAnswerOptionFromLocalStorage();
    }
  }, [question, externalUserId, userAnswerOptionId]);

  const isPastVoteEndDate =
    question.vote_end_date && new Date(question.vote_end_date) < new Date();

  return (
    <ContentWrapper
      style={{ position: "relative", paddingBottom: "8px", padding: "0px" }}
    >
      {question.image_url ? (
        <ImageOverlay
          imageUrl={question.image_url}
          title={question.title}
          disableBorderBottom={true}
          description={question.description}
          style={{ margin: "-38px -12px 12px -12px" }}
        />
      ) : (
        <>
          <Text variant="title" style={{ color: theme.accent_color }}>
            {question.title}
          </Text>
          <br />
          <Text variant="subtitle">{question.description}</Text>
        </>
      )}
      {isPastVoteEndDate && (
        <Text variant="small" color="gray" style={{ fontStyle: "italic" }}>
          Tiden för att svara på denna fråga löpte ut{" "}
          {dayjs(new Date(question.vote_end_date)).format("DD MMMM YYYY HH:mm")}
        </Text>
      )}
      {selectedAnswerOption || isPastVoteEndDate ? (
        <div>
          <Results question={question} answerOptionId={selectedAnswerOption} />
          <span
            style={{
              color: theme.accent_color,
              cursor: "pointer",
              fontSize: "small",
            }}
            onClick={() => toggleWindowSize(true)}
          >
            Se mer
          </span>
        </div>
      ) : (
        <AnswerQuestion
          skipFeeling={!question.enable_feelings}
          externalUserId={externalUserId ? externalUserId : undefined}
          onClick={(ao) => {
            refreshQuestion();
            setSelectedAnswerOption(ao);
            toggleWindowSize(true);
          }}
          question={question}
          webpageId={webpageId}
          panelId={panelId}
        />
      )}
    </ContentWrapper>
  );
};
