import { useEffect, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useCustomerTheme } from "../contexts/CustomerThemeContext";
import { getMoreQuestions } from "../helpers/primaryApiFunctions";
import { BUTTON_TEXT } from "../utils/theme";
import { Button } from "./ui/Button";
import { QuestionFeedItem } from "./ui/QuestionFeedItem";
import { Text } from "./ui/Text";

export const MoreQuestions = ({
  question,
  externalUserId,
  panelId,
  handleNewActiveQuestion,
}) => {
  const theme = useCustomerTheme();
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const pageSize = 5;

  const handleMoreQuestions = async (extendQuestions) => {
    setLoading(true);
    const items = await getMoreQuestions(
      panelId,
      question.id,
      externalUserId,
      page,
      pageSize
    );
    if (extendQuestions) {
      setQuestions([...questions, ...items]);
    } else {
      setQuestions(items);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (panelId && question?.id && page !== 1) {
      handleMoreQuestions(true);
    }
  }, [page]);

  useEffect(() => {
    if (panelId && question?.id) {
      setPage(1);
      handleMoreQuestions(false);
    }
  }, [question, panelId]);

  return (
    <FadeIn style={{ marginTop: "20px" }}>
      <Text
        variant="subtitle"
        style={{ fontWeight: "bold", marginBottom: "16px" }}
      >
        Andra har även svarat på
      </Text>
      <div style={{ minHeight: "140px" }}>
        {questions.length === 0 && !loading && (
          <Text color="gray">Vi hittar tyvärr inga fler frågor</Text>
        )}
        {questions.map((q) => (
          <FadeIn key={q.id}>
            <QuestionFeedItem question={q} onClick={handleNewActiveQuestion} />
          </FadeIn>
        ))}

        {questions.length >= page * pageSize && (
          <Button
            onClick={() => setPage(page + 1)}
            text={"Se fler frågor"}
            style={{
              width: "100%",
              borderRadius: `${theme.border_radius}px` || "8px",
              backgroundColor: theme.accent_color,
              color: BUTTON_TEXT,
            }}
          />
        )}
      </div>
    </FadeIn>
  );
};
