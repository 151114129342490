import { useCustomerTheme } from "../../contexts/CustomerThemeContext";

export const ContentWrapper = ({ children, style = {} }) => {
  const theme = useCustomerTheme();

  return (
    <div
      style={{
        borderRadius: `${theme.border_radius}px` || "8px",
        padding: "10px",
        marginBottom: "5px",
        fontSize: "medium",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
