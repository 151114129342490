import { useCustomerTheme } from "../../contexts/CustomerThemeContext";
import { BOX_GRAY } from "../../utils/theme";

export const Card = ({ children, style = {}, onClick = () => {} }) => {
  const theme = useCustomerTheme();

  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: BOX_GRAY,
        borderRadius: `${theme.border_radius}px` || "8px",
        padding: "10px",
        marginBottom: "5px",
        ...style,
      }}
    >
      {children}
    </div>
  );
};
