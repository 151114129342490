import { useEffect, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { MdArrowForward } from "react-icons/md";
import { useCustomerTheme } from "../contexts/CustomerThemeContext";
import { fetchUnAnsweredPanelAttributeQuestion } from "../helpers/primaryApiFunctions";
import { supabase } from "../utils/Api";
import { feelingsItems } from "../utils/feelings";
import { setLocalStorageItem } from "../utils/localstorage";
import { BUTTON_TEXT, getFeelingColor } from "../utils/theme";
import { Button } from "./ui/Button";
import { ImageOverlay } from "./ui/ImageOverlay";
import { Text } from "./ui/Text";

export const AnswerQuestion = ({
  question,
  externalUserId,
  onClick,
  webpageId,
  skipFeeling,
  panelId,
}) => {
  const theme = useCustomerTheme();
  const [answerOptionId, setAnswerOptionId] = useState();
  const [followUpQuestion, setFollowUpQuestion] = useState(null);

  useEffect(() => {
    if (skipFeeling && panelId) {
      // Todo: Get a profile question that the user has not answered.
      fetchUnAnsweredPanelAttributeQuestion(externalUserId, panelId).then(
        (question) => {
          if (question) {
            setFollowUpQuestion(question);
          } else {
            setFollowUpQuestion(null);
          }
        }
      );
    }
  }, [skipFeeling, panelId]);

  const answerQuestion = async (answerOptionId, feeling, questionId) => {
    try {
      if (externalUserId) {
        await storeAnswerFromExternalUser(answerOptionId, feeling, questionId);
      } else {
        await storeAnswerFromAnonymous(answerOptionId, feeling, questionId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAnswerOptionClick = async (answerOptionId, feeling) => {
    await answerQuestion(answerOptionId, feeling ?? "UNKNOWN", question.id);
    setLocalStorageItem(question.id, answerOptionId);
    onClick(answerOptionId);
  };

  const handleFollowUpAnswerOptionClick = async (
    followUpQuestionAnswerOptionId,
    feeling
  ) => {
    await answerQuestion(
      followUpQuestionAnswerOptionId,
      feeling ?? "UNKNOWN",
      followUpQuestion.id
    );
    handleAnswerOptionClick(answerOptionId, "UNKNOWN");
    setLocalStorageItem(followUpQuestion.id, followUpQuestionAnswerOptionId);
  };

  const storeAnswerFromAnonymous = async (aoID, f, questionId) => {
    const params = {
      input_answer_option_id: aoID,
      input_feeling: f ?? "UNKNOWN",
      input_question_id: questionId,
      input_webpage_id: webpageId,
    };
    return await supabase.rpc("store_answer_from_anonymous_user_v2", params);
  };

  const storeAnswerFromExternalUser = async (aoID, f, questionId) => {
    if (externalUserId && question) {
      const params = {
        input_answer_option_id: aoID,
        input_external_user_id: externalUserId,
        input_question_id: questionId,
        input_feeling: f,
        input_webpage_id: webpageId,
      };
      return await supabase.rpc("store_answer_from_external_user_v4", params);
    }
  };

  if (!answerOptionId) {
    return (
      <FadeIn>
        {question.answer_options
          .sort(
            (a, b) =>
              (a?.answer_option_order ?? 0) - (b?.answer_option_order ?? 0)
          )
          .map((ao) => (
            <div
              key={ao.id}
              style={{ marginTop: "8px", cursor: "pointer" }}
              onClick={() => {
                if (skipFeeling && !followUpQuestion) {
                  handleAnswerOptionClick(ao.id, "UNKNOWN");
                } else {
                  setAnswerOptionId(ao.id);
                }
              }}
            >
              {ao.image_url ? (
                <ImageOverlay
                  imageUrl={ao.image_url}
                  title={ao.answer_title}
                  paddingTop="100px"
                />
              ) : (
                <Button
                  text={ao.answer_title}
                  style={{
                    width: "100%",
                    borderRadius: `${theme.border_radius}px` || "8px",
                    backgroundColor: theme.accent_color,
                    color: BUTTON_TEXT,
                  }}
                />
              )}
            </div>
          ))}
        <Text variant="small" color="gray" style={{ marginTop: "8px" }}>
          Ditt svar är anonymt
        </Text>
      </FadeIn>
    );
  } else if (answerOptionId && !followUpQuestion && !skipFeeling) {
    return (
      <FadeIn>
        <Text variant="subtitle">Hur känner du inför det?</Text>
        {feelingsItems.map((f) => (
          <div key={f.value} style={{ marginTop: "8px" }}>
            <Button
              type={f.value}
              onClick={() => {
                handleAnswerOptionClick(answerOptionId, f.value);
              }}
              text={f.label}
              style={{
                width: "100%",
                borderRadius: `${theme.border_radius}px` || "8px",
                backgroundColor: getFeelingColor(f.value),
                color: BUTTON_TEXT,
              }}
            />
          </div>
        ))}
        <Text variant="small" style={{ marginTop: "8px", color: BUTTON_TEXT }}>
          Ditt svar är anonymt
        </Text>
      </FadeIn>
    );
  } else if (answerOptionId && followUpQuestion && skipFeeling) {
    return (
      <FadeIn>
        <Text
          color="accent"
          variant="small"
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => handleAnswerOptionClick(answerOptionId, "UNKNOWN")}
        >
          Hoppa över och se resultatet <MdArrowForward />
        </Text>
        <Text variant="subtitle">Följdfråga: {followUpQuestion.title}</Text>
        {followUpQuestion.answer_options
          .sort(
            (a, b) =>
              (a?.answer_option_order ?? 0) - (b?.answer_option_order ?? 0)
          )
          .map((ao) => (
            <div
              key={ao.id}
              style={{ marginTop: "8px", cursor: "pointer" }}
              onClick={() => {
                handleFollowUpAnswerOptionClick(ao.id, "UNKNOWN");
              }}
            >
              {ao.image_url ? (
                <ImageOverlay
                  imageUrl={ao.image_url}
                  title={ao.answer_title}
                  paddingTop="100px"
                />
              ) : (
                <Button
                  text={ao.answer_title}
                  style={{
                    width: "100%",
                    borderRadius: `${theme.border_radius}px` || "8px",
                    backgroundColor: theme.accent_color,
                    color: BUTTON_TEXT,
                  }}
                />
              )}
            </div>
          ))}
        <Text variant="small" color="gray" style={{ marginTop: "8px" }}>
          Ditt svar är anonymt
        </Text>
      </FadeIn>
    );
  }
};
