import { supabase } from "../utils/Api";
import { Button } from "./ui/Button";
import { Card } from "./ui/Card";
import { Text } from "./ui/Text";

export const CustomCTA = ({ question }) => {
  const registerCTAClick = async () => {
    try {
      const query = await supabase.from("tracking_cta_clicks").insert({
        question_id: question.id,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Card>
      <div style={{ marginBottom: "8px", marginTop: "8px" }}>
        <Text variant="subtitle">{question.title}</Text>
      </div>
      <div>
        <Text>
          Du behöver en prenumeration för att svara på denna fråga och se
          resultatet.
        </Text>
        {window.mentoCustomCtaUrl && (
          <Button
            onClick={registerCTAClick}
            href={`${window.mentoCustomCtaUrl}?utm_source=mento-social&utm_medium=mento-social-plugin&utm_campaign=cta-click`}
            text={"Prenumerera"}
          />
        )}
      </div>
    </Card>
  );
};
