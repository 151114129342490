import React from "react";

import { useCustomerTheme } from "../../contexts/CustomerThemeContext";
import { Card } from "./Card";
import { Text } from "./Text";

export const QuestionFeedItem = ({ question, onClick }) => {
  const theme = useCustomerTheme();

  const formatTimeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const diffInDays = Math.floor(
      (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
    );
    return `${diffInDays} dagar sedan`;
  };

  return (
    <Card
      onClick={() => onClick(question)}
      style={{
        marginBottom: "12px",
        padding: "12px",
        cursor: "pointer",
      }}
    >
      <div style={{ marginBottom: "8px" }}>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}
        >
          {question?.panels?.image_url && (
            <img
              alt={question?.panels?.name}
              src={
                "https://gyrstiqzamltthbjartl.supabase.co/storage/v1/object/public/panel-images/" +
                question?.panels?.image_url
              }
              style={{
                width: "32px",
                height: "32px",
                borderRadius: "20%",
              }}
            />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
            }}
          >
            {question?.panels?.name && (
              <Text
                variant="subtitle"
                style={{
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {question?.panels?.name}
              </Text>
            )}
            {question?.published_date && (
              <Text variant="small" color="gray" style={{ marginLeft: "8px" }}>
                {formatTimeAgo(question.published_date)}
              </Text>
            )}
          </div>
        </div>
        <Text
          variant="subtitle"
          onClick={() => onClick(question)}
          style={{
            fontWeight: "bold",
            color: theme.accent_color,
            marginBottom: "4px",
            cursor: "pointer",
          }}
        >
          {question?.title}
        </Text>
        {question?.description && (
          <>
            <br />
            <Text color="gray">{question?.description}</Text>
          </>
        )}
      </div>

      {question.image_url && (
        <img
          src={question.image_url}
          alt={question.title}
          onClick={(e) => {
            e.stopPropagation();
            e.target.style.maxHeight = e.target.style.maxHeight
              ? null
              : "180px";
          }}
          style={{
            width: "100%",
            height: "auto",
            maxHeight: "180px",
            objectFit: "cover",
            borderRadius: `${theme.border_radius}px` || "8px",
            borderBottomRightRadius: theme.border_radius,
            borderBottomLeftRadius: theme.border_radius,
            cursor: "pointer",
          }}
        />
      )}
    </Card>
  );
};
