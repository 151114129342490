import { useEffect, useRef, useState } from "react";
import { TfiAngleLeft, TfiAngleRight } from "react-icons/tfi";
import { useCustomerTheme } from "../contexts/CustomerThemeContext";
import {
  fetchAnswerOptionsStatisticsForQuestionAndRelatedQuestion,
  fetchUserAnswer,
} from "../helpers/primaryApiFunctions";
import { calculatePercentage } from "../utils/formatAnswerOptions";
import { TEXT_GRAY } from "../utils/theme";
import { AnswerQuestion } from "./AnswerQuestion";
import BlurredResults from "./BlurredResults";
import { ContentWrapper } from "./layout/ContentWrapper";
import { Card } from "./ui/Card";
import { IconButton } from "./ui/IconButton";
import { ImageOverlay } from "./ui/ImageOverlay";
import ResultItem from "./ui/ResultItem";
import { Text } from "./ui/Text";

export const ProfileQuestionAnswerOptions = ({
  question,
  originalQuestionId,
  externalUserId,
  webpageId,
  userOriginalQuestionAnswerOptionId,
}) => {
  const [formattedAnswerOptions, setFormattedAnswerOptions] = useState([]);
  const [userAnswerOptionId, setUserAnswerOptionId] = useState(null);
  const [blurResult, setBlurResult] = useState(true);
  const theme = useCustomerTheme();
  const scrollRef = useRef(null);

  useEffect(() => {
    // Todo: Check if the current user has answered. If yes, fetchAnswerOptionsStatistics and display result. If no, display answer question.
    const checkIfUserHasAnswered = async () => {
      const data = await fetchUserAnswer(question.id, externalUserId);
      setUserAnswerOptionId(data);
    };
    checkIfUserHasAnswered();
  }, [externalUserId, question.id]);

  useEffect(() => {
    const fetchAnswerOptionsStatistics = async () => {
      const data =
        await fetchAnswerOptionsStatisticsForQuestionAndRelatedQuestion(
          originalQuestionId,
          question.id,
          externalUserId
        );

      console.log(data);

      if (data) {
        const formattedData = data.reduce((acc, item) => {
          let existingOption = acc.find(
            (option) =>
              option.related_answer_option_id === item.related_answer_option_id
          );

          if (!existingOption) {
            existingOption = {
              related_answer_option_id: item.related_answer_option_id,
              related_answer_title: item.related_answer_title,
              related_answer_image_url: item.related_answer_image_url,
              items: [],
              totalAmount: 0,
            };
            acc.push(existingOption);
          }

          existingOption.totalAmount += item.amount;
          existingOption.items.push({
            id: item.answer_option_id,
            text: item.answer_title,
            image_url: item.related_answer_image_url,
            amount: item.amount,
            selected: item.selected,
          });
          return acc;
        }, []);
        formattedData.forEach((option) => {
          option.items = option.items.map((item) => ({
            ...item,
            percentage: calculatePercentage(option.totalAmount, item.amount),
          }));
        });
        setFormattedAnswerOptions(
          formattedData.sort((a, b) => b.totalAmount - a.totalAmount)
        );
      }
    };

    if (userAnswerOptionId) {
      fetchAnswerOptionsStatistics();
    }
  }, [userAnswerOptionId, originalQuestionId]);

  const scrollContainer = (direction) => {
    if (scrollRef.current) {
      const scrollAmount = 200; // Adjust scroll amount as needed
      scrollRef.current.scrollBy({
        left: direction * scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return userAnswerOptionId ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginLeft: "-15px",
        marginRight: "-15px",
      }}
    >
      <IconButton
        icon={<TfiAngleLeft />}
        onClick={() => scrollContainer(-1)}
        color={theme.accent_color}
        disableBorder={true}
        style={{ marginRight: "3px" }}
      />
      <div
        className="mento-scroll-container"
        style={{
          display: "flex",
          overflowX: "scroll",
          scrollSnapType: "x mandatory",
          width: "100%",
        }}
        ref={scrollRef}
      >
        {formattedAnswerOptions.map((fao) => (
          <div
            key={fao.related_answer_option_id}
            style={{
              flex: "0 0 auto",
              scrollSnapAlign: "start",
              width: "100%",
              marginRight: "10px",
            }}
          >
            {fao.related_answer_image_url ? (
              <ImageOverlay
                style={{ marginBottom: "0px" }}
                imageUrl={fao.related_answer_image_url}
                title={
                  fao.related_answer_title +
                  (fao.related_answer_option_id === userAnswerOptionId
                    ? "(Ditt svar)"
                    : "")
                }
                paddingTop="110px"
                disableBorderBottom={true}
              />
            ) : (
              <span style={{ fontWeight: "bold", color: TEXT_GRAY }}>
                {fao.related_answer_title}{" "}
                {fao.related_answer_option_id === userAnswerOptionId &&
                  "(Ditt svar)"}
              </span>
            )}
            <Card style={{ paddingTop: "0px" }}>
              {fao.items.map((item, index) => (
                <div key={index}>
                  <ResultItem
                    item={item}
                    answerOptionId={userOriginalQuestionAnswerOptionId}
                  />
                </div>
              ))}
            </Card>
          </div>
        ))}
      </div>
      <IconButton
        icon={<TfiAngleRight />}
        disableBorder={true}
        onClick={() => scrollContainer(1)}
        color={theme.accent_color}
        style={{ marginLeft: "3px" }}
      />
    </div>
  ) : blurResult ? (
    <BlurredResults onViewResultClick={() => setBlurResult(false)} />
  ) : (
    <ContentWrapper>
      <Text variant="subtitle" style={{ fontWeight: "bold" }}>
        {question.title}
      </Text>
      <AnswerQuestion
        webpageId={webpageId}
        question={question}
        externalUserId={externalUserId}
        onClick={(answerOptionId) => setUserAnswerOptionId(answerOptionId)}
        skipFeeling
      />
    </ContentWrapper>
  );
};
