import { useEffect, useState } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useCustomerTheme } from "../contexts/CustomerThemeContext";
import { fetchPanelAttributeQuestionsWithAnswers } from "../helpers/primaryApiFunctions";
import { ProfileQuestionAnswerOptions } from "./ProfileQuestionAnswerOptions";
import { Text } from "./ui/Text";

export const ProfileQuestions = ({
  originalQuestionId,
  externalUserId,
  panelId,
  webpageId,
  userAnswerOptionId,
}) => {
  const [profileQuestions, setProfileQuestions] = useState([]);
  const theme = useCustomerTheme();

  useEffect(() => {
    const fetchQuestions = async () => {
      const questions = await fetchPanelAttributeQuestionsWithAnswers(
        externalUserId,
        panelId
      );
      setProfileQuestions(questions || []);
    };

    fetchQuestions();
  }, [externalUserId]);

  return (
    <>
      {profileQuestions.map((question) => (
        <div
          key={question.id}
          style={{
            marginTop: "16px",
            borderRadius: theme.border_radius,
          }}
        >
          <div
            style={{
              position: "relative",
              borderRadius: theme.border_radius,
            }}
          >
            {/* {question?.image_url ? (
              <ImageOverlay
                maxHeight="70px"
                imageUrl={question.image_url}
                title={
                  question?.statistics_heading
                    ? `Resultat fördelat på ${question?.statistics_heading}`
                    : question?.title
                }
                disableBorderBottom={true}
              />
            ) : ( */}
            <Text
              variant="subtitle"
              style={{
                fontWeight: "bold",
                color: theme.accent_color,
                paddingBottom: "8px",
              }}
            >
              {question?.statistics_heading
                ? `Resultat fördelat på ${question?.statistics_heading}`
                : question?.title}
            </Text>
            {/* )} */}
          </div>
          <FadeIn
            style={{
              marginTop: "8px",
            }}
          >
            <ProfileQuestionAnswerOptions
              userOriginalQuestionAnswerOptionId={userAnswerOptionId}
              externalUserId={externalUserId}
              question={question}
              originalQuestionId={originalQuestionId}
              webpageId={webpageId}
            />
          </FadeIn>
        </div>
      ))}
    </>
  );
};
